import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GoalsState } from '@wam/shared';

const getGoalsState = createFeatureSelector<GoalsState>('goals');

export const getHits = createSelector(getGoalsState, (state) => state.hits);

export const getCourses = createSelector(getGoalsState, (state) => state.courses);

export const getClassHasStudents = createSelector(getGoalsState, (state) => state.classHasStudents);

export const getLevel = createSelector(getGoalsState, (state) => state.level);

export const getFacets = createSelector(getGoalsState, (state) => state.facets);

export const getSelectedFacets = createSelector(getGoalsState, (state) => state.selectedFacets);

export const getPage = createSelector(getGoalsState, (state) => state.page);

export const getGoals = createSelector(getGoalsState, (state) => state.goals);

export const getTargets = createSelector(getGoalsState, (state) => state.targets);

export const getBenchmarks = createSelector(getGoalsState, (state) => state.benchmarks);

export const getLocation = createSelector(getGoalsState, (state) => state.locations);

export const getLocks = createSelector(getGoalsState, (state) => state.locks);
