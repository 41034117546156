import { createAction, props } from '@ngrx/store';
import {
  Benchmark,
  ClassroomAnalytics,
  Facets,
  LevelLocation,
  MetricTarget,
  Product,
  ProductLocation,
  StudentAnalytics,
  ViewLevel,
  WelGoal,
} from '@wam/shared';
import { Course } from '@app/shared/course.model';
import { AssignmentResponse } from '@wap/catalog-v5';

export const load = createAction('[Goals Tab] Load');

export const continueLoad = createAction('[Goals Tab] Continue Load');

export const loadClassCourses = createAction(
  '[Goals Tab] Load class courses',
  props<{ classUuid: string }>(),
);

export const loadSuccess = createAction(
  '[Goals Tab] Load success',
  props<{ hits: ClassroomAnalytics[] | StudentAnalytics[]; totalHits: number }>(),
);

export const continueLoadSuccess = createAction(
  '[Goals Tab] Continue Load success',
  props<{ hits: ClassroomAnalytics[] | StudentAnalytics[]; totalHits: number }>(),
);

export const loadClassAssignmentsSuccess = createAction(
  '[Goals Tab] Load courses for class success',
  props<{ courses: { [key: string]: AssignmentResponse[] } }>(),
);

export const updateClassHasStudents = createAction(
  '[Load Class Assignments] Update class has students',
  props<{ classUuid: string; hasStudents: boolean }>(),
);

export const loadStudentCourses = createAction(
  '[Goals Tab] Load student courses',
  props<{ student: StudentAnalytics }>(),
);

export const loadStudentCoursesSuccess = createAction(
  '[Goals Tab] Load courses for student success',
  props<{ student: StudentAnalytics; courses: AssignmentResponse[] }>(),
);

export const studentCoursesChanged = createAction(
  '[Goals Tab] Student courses changed',
  props<{ student: StudentAnalytics; courses: Course[] }>(),
);

export const studentCoursesChangedSuccess = createAction(
  '[Goals Tab] Student courses changed success',
  props<{ student: StudentAnalytics; courses: Course[] }>(),
);

export const studentCoursesChangedFailed = createAction(
  '[Goals Tab] Student courses changed failed',
  props<{ student: StudentAnalytics; courses: Course[] }>(),
);

export const classCoursesChanged = createAction(
  '[Goals Tab] Class course changed',
  props<{ classUuid: string; courses: Course[] }>(),
);

export const updateFacets = createAction('[Goals API] Update facets', props<{ facets: Facets }>());

export const updateSelectedFacets = createAction(
  '[Goals Tab] Update selected facets',
  props<{ selectedFacets: Facets }>(),
);

export const updateLevel = createAction('[Goals Tab] Update Level', props<{ level: ViewLevel }>());

export const loadGoals = createAction(
  '[Goals Tab] Load goals',
  props<{ organization: string; entity: string }>(),
);

export const loadGoalsSuccess = createAction(
  '[Goals API] Load goals success',
  props<{ goals: { [key: string]: WelGoal[] } }>(),
);

export const goalChanged = createAction(
  '[Goals Tab] Goal changed',
  props<{ organization: string; entity: string; goal: WelGoal }>(),
);

export const loadTargets = createAction('[Goals Tab] Load targets');

export const loadOrgLocations = createAction('[Goals Tab] Load organization locations');

export const loadTargetsSuccess = createAction(
  '[Goals API] Load targets success',
  props<{ targets: { [key: string]: MetricTarget[] } }>(),
);

export const loadStudentLocation = createAction(
  '[Goals Tab] Load locations',
  props<{ organization: string; entity: string; products: { erp: boolean; ems: boolean } }>(),
);

export const loadStudentLocationSuccess = createAction(
  '[Goals Tab] Load locations success',
  props<{
    entity: string;
    locations: { [key: string]: ProductLocation | {} };
  }>(),
);

export const loadLocationsForProduct = createAction(
  '[Goals Tab] Load locations for product',
  props<{ organization: string; entity: string; product: Product }>(),
);

export const loadLocationsForProductSuccess = createAction(
  '[Goals Tab] Load locations for product success',
  props<{
    entity: string;
    locations: { [key: string]: ProductLocation | {} };
  }>(),
);

export const studentLocationChanged = createAction(
  '[Goals Tab] Location changed',
  props<{ organization: string; entity: string; location: LevelLocation }>(),
);

export const classLocationChanged = createAction(
  '[Goals Tab] Class location changed',
  props<{ organization: string; entity: string; location: LevelLocation }>(),
);

export const loadBenchmarks = createAction(
  '[Goals Tab] Load benchmarks',
  props<{ organization: string; entity: string }>(),
);

export const loadBenchmarksFromStudentDialog = createAction(
  '[Student Dialog] Load benchmarks',
  props<{ organization: string; entity: string }>(),
);

export const loadBenchmarksSuccess = createAction(
  '[Goals API] Load benchmarks success',
  props<{ benchmarks: { [key: string]: Benchmark[] } }>(),
);

export const benchmarkChanged = createAction(
  '[Goals Tab] Benchmark changed',
  props<{ organization: string; entity: string; benchmark: Benchmark }>(),
);

export const benchmarksCanceled = createAction(
  '[Goals Tab] Cancel benchmarks changes',
  props<{ entity: string }>(),
);

export const loadGradeGoals = createAction(
  '[Goals Tab] Load Grade Goals',
  props<{ organization: string; entity: string; grade: string; product: Product }>(),
);

export const loadGradeGoalsFailed = createAction(
  '[Goals API] Load Grade Goals Failed',
  props<{ error: string }>(),
);

export const cancelGradeGoal = createAction(
  '[Upper Level Goals Card] Cancel Grade Goal',
  props<{ goal: WelGoal }>(),
);

export const saveGradeGoal = createAction(
  '[Upper Level Goals Card] Save Grade Goal',
  props<{ goal: WelGoal }>(),
);

export const saveGradeGoalSuccess = createAction(
  '[Goals API] Save Grade Goal Success',
  props<{ goal: WelGoal }>(),
);

export const saveGradeGoalFailed = createAction(
  '[Goals API] Save Grade Goal Failed',
  props<{ error: string }>(),
);

export const loadLock = createAction(
  '[Upper Level Goals Card] Load Lock',
  props<{ organization: string; entity: string | string[] }>(),
);

export const loadLockSuccess = createAction(
  '[Goals API] Load Lock Success',
  props<{ lock: { [key: string]: boolean } }>(),
);

export const loadLockFailed = createAction(
  '[Goals API] Load Lock Failed',
  props<{ error: string }>(),
);

export const updateLock = createAction(
  '[Goals Component] Update Lock',
  props<{ organization: string; entity: string; locked: boolean }>(),
);

export const updateLockSuccess = createAction('[Goals API] Update Lock Success');

export const updateLockFailed = createAction(
  '[Goals API] Update Lock Failed',
  props<{ organization: string; entity: string; locked: boolean }>(),
);

export const cancelLock = createAction(
  '[Upper Level Goals Card] Cancel Lock',
  props<{ lockId: string }>(),
);
